import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import i18n from "./i18n";
import {connect} from "react-redux";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));

class App extends Component {
  state = {
    isAuth: false,
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    const lng = localStorage.getItem('lng');
    const {dispatch} = this.props;
    if(!!lng){
      dispatch({type: 'set', lng });
      i18n.changeLanguage(lng);
    }else{
      localStorage.setItem('lng', 'ru');
    }
    if(!!token){
      this.setState({isAuth: true});
    }
  }

  isAuthChange = mode => this.setState({isAuth: mode});

  render() {
    const {isAuth} = this.state;

    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login isAuth={isAuth} isAuthChange={this.isAuthChange} {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout isAuth={isAuth} isAuthChange={this.isAuthChange} {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default connect()(App);
